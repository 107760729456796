import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import ReactMarkdown from "react-markdown";
import HeaderText from "./HeaderText";

function AboutUsMobile(props) {
    const { t } = useTranslation();
    const context = React.useContext(I18nextContext);
    const textColor = `text-[#${props.website.color_text_body}]`;
    return (
        <div className="flex flex-col pt-20">
            <div className="flex flex-col place-items-center">
                <GatsbyImage image={getImage(props.website.img1)} alt={"Image 1"} className="tablet:flex place-self-start"/>
                <div className="space-y-5 flex flex-col tablet:flex place-self-start pt-10 pl-10">
                    <HeaderText text={
                        props.website.about_us[context.language].trim() == ''
                            ? ''
                            : t('About us')} colorHeader={props.website.color_text_header} />
                </div>
                <div style={{color: `#${props.website.color_text_body}`}} className={`flex flex-col items-center pt-5 pl-10 pr-10 space-y-5 font-medium`}>
                    <ReactMarkdown>{props.website.about_us[context.language]}</ReactMarkdown>
                </div>
            </div>

            <div className="grid grid-rows-2 place-items-center -space-y-3 pt-20 tablet:pt-20">
                <div>
                    <GatsbyImage image={getImage(props.website.img2)} alt={"Image 2"} />
                </div>
                <div>
                    <GatsbyImage image={getImage(props.website.img3)} alt={"Image 3"} />
                </div>
            </div>
        </div>
    );
}

export default AboutUsMobile;
