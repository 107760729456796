import React from "react";
import PartnerGeneralInfoDesktop from "../Components/PartnerGeneralInfoDesktop";
import RestaurantIntroduce from "../Components/RestaurantIntroduce";
import OpeningHours from "../Components/OpeningHours";
import AboutUsMobile from "../Components/AboutUsMobile";
import AboutUsDesktop from "../Components/AboutUsDesktop";
import PartnerGeneralInfoMobile from "../Components/PartnerGeneralInfoMobile";
import { graphql } from 'gatsby';
import Layout from "../Components/Layout";


export default function Index(props) {
  // console.log('Index props');
  // console.log(props);
  return (
    //  <Layout {...props}>
    <>
      <div className="desktop:hidden"><PartnerGeneralInfoMobile status="Geöffnet" website={props.data.website} /></div>
      <div className="tablet:hidden mobile:hidden"><PartnerGeneralInfoDesktop status="Geöffnet" website={props.data.website} /></div>

      <RestaurantIntroduce website={props.data.website} />
      {/* <OpeningHours website={props.data.website} /> */}
      <OpeningHours {...props} />

      <div className="desktop:hidden"> <AboutUsMobile website={props.data.website} /></div>
      <div className="tablet:hidden  mobile:hidden"><AboutUsDesktop website={props.data.website} /></div>
    </>
    //  </Layout>
  );
};

// 3d8b98b5-c65a-4a96-a824-1e0b83d8ecd9
// 24c18c35-6b96-4b3d-9c45-b3c049a21f62
//website(partner_id: {eq: "1158daee-046a-4639-9b4b-9057351ff7f2"}) {
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    website {
      id
      name
      default_locale
      supported_lang {
        it
        en
        de
      } 
      min_delivery_value
      delivery_cost
      delivery_time
      about_us {
        de
        en
        it
      }
      address {
        city
        no
        street
        zip_code
      }
      categories
      color
      color_background
      color_text_body
      color_text_header
      contact {
        email
        telephone
      }
      default_locale
      data_protection {
        de
        en
        it
      }
      description {
        de
        en
        it
      }
    
      image_url
      images {
        img1
        img2
        img3
      }
      imprint {
        de
        en
        it
      }
      legal {
        de
        en
        it
      }
      logo_url
      order_options {
        delivery
        on_site
        take_away
      }
      short_description {
        de
        en
        it
      }
      social_links {
        facebook
        instagram
      }
      supported_lang {
        de
        en
        it
      }
      top_text {
        de
        en
        it
      }
      lst_opening_hours_delivery_str {
        end       
        start
        weekday
      }
      lst_opening_hours_on_site_str {
        end      
        start
        weekday
      }
      lst_opening_hours_take_away_str {
        end     
        start
        weekday
      }
      webslug
      imgCover {
        childImageSharp {
          gatsbyImageData(            
            placeholder: BLURRED
            
          )
        }
      }
      img1 {
        childImageSharp {
          gatsbyImageData(            
            placeholder: BLURRED
            
          )
        }
      }
      img2 {
        childImageSharp {
          gatsbyImageData(            
            placeholder: BLURRED
            
          )
        }
      }
      img3 {
        childImageSharp {
          gatsbyImageData(            
            placeholder: BLURRED
            
          )
        }
      }
      imgLogo {
        childImageSharp {
          gatsbyImageData(            
            placeholder: BLURRED
            
          )
        }
      }
    }
  }
`
