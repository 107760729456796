import React from "react";
import iconDelivery from "../images/openinghour_delivery.svg";
import iconOnSite from "../images/openinghour_onsite.svg";
import iconTakeaway from "../images/openinghour_takeaway.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";

function renderOpeningHours(lstOpeningHour, lang, textColor) {
    const { t } = useTranslation();
    const days = [t("Monday"), t("Tuesday"), t("Wednesday"), t("Thursday"), t("Friday"), t("Saturday"), t("Sunday")];
    const dayCodes = {
        "mon": 0,
        "tue": 1,
        "wed": 2,
        "thu": 3,
        "fri": 4,
        "sat": 5,
        "sun": 6,
    }
    // convert to map of day -> entry
    let weekdayOpeningHour = {};
    for (let day = 0; day <= 6; day++) {
        weekdayOpeningHour[day] = [];
    }
    lstOpeningHour.forEach((entry) => {
        weekdayOpeningHour[dayCodes[entry.weekday]].push(entry);
    });

    return [0, 1, 2, 3, 4, 5, 6].map((day) => {
        return (
            <div className="flex flex-row place-content-between" key={day}>
                <div style={{color: `#${textColor}`}} className="w-20 pt-3 pl-3 font-medium">{days[day]}</div>
                <div style={{color: `#${textColor}`}} className="w-32 pt-3 pr-3 flex flex-col justify-end font-medium text-textColor items-end">
                    {
                        weekdayOpeningHour[day].length == 0
                            ? t('Closed')
                            : weekdayOpeningHour[day].map((timeEntry) => {
                                const start = timeEntry["start"];
                                const end = timeEntry["end"];
                                return <div key={timeEntry}>{start} - {end}</div>;
                            })
                    }
                </div>
            </div>);
    });
}

const OpeningHoursTable = (props) => {
    const openingHours = renderOpeningHours(props.openingHoursList, props.lang, props.textColor);
    
    return (
        <div className="w-full mr-10 text-base">
            <div className="bg-gray-100 h-10 rounded-lg flex justify-between">
                <div style={{color: `#${props.accentColor}`}} className={`ml-3 flex items-center justify-start font-medium pt-1 text-base`}>{props.tableName}</div>
                <div className="flex items-center justify-start mr-3 ">
                    <IconImage order_option={props.order_option} />
                </div>
            </div>
            <div>{openingHours}</div>
        </div>
    );
};
const IconImage = (props) => {
    if (props.order_option == "delivery") {
        return <img src={iconDelivery} className="object-contain h-5 w-5"></img>;
    }
    if (props.order_option == "take away") {
        return <img src={iconTakeaway} className="object-contain h-5 w-5"></img>;
    }
    if (props.order_option == "on site") {
        return <img src={iconOnSite} className="object-contain h-5 w-5"></img>;
    }
};
export default OpeningHoursTable;
