import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "./Button";
import facebook_icon from "../images/facebook.svg";
import instagram_icon_1 from "../images/Pfad 418.svg";
import instagram_icon_2 from "../images/Pfad 419.svg";
import { Buffer } from 'buffer';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Convert svg data from base64 to String, replace the color code, reconvert
function replaceColorSvg(svgData: string, oldCode: string, newCode: string): string {
    const prefix = 'data:image/svg+xml;base64,';
    const onlyBase64 = svgData.replace(prefix, '');
    const plainTextOld = Buffer.from(onlyBase64, 'base64').toString('ascii');
    const plainTextNew = plainTextOld.replaceAll(oldCode, newCode);
    const result = prefix + Buffer.from(plainTextNew, 'utf8').toString('base64');
    return result;
}


const PartnerGeneralInfoDesktop = (props) => {
    const categoryLst = props.website.categories.join(" | ");

    const { t } = useTranslation();
    const accentColor = '#' + props.website.color;
    const instagram_icon_1_new = replaceColorSvg(instagram_icon_1, '#36caf4', accentColor);
    const instagram_icon_2_new = replaceColorSvg(instagram_icon_2, '#36caf4', accentColor);
    const facebook_icon_new = replaceColorSvg(facebook_icon, '#36caf4', accentColor);


    return (
        <div className="flex aspect-[5/2]">
            <div className="relative w-[47%] h-full flex aspect-6/5 justify-center">
                <StaticImage src="./../images/bg-pic2.png" alt="PIC5" className="opacity-10 object-cover" />
                <div className="absolute place-self-center">
                    <div style={{color: `#${props.website.color_text_body}`}} className="relative flex flex-col space-y-10">
                        <div className="flex h-56 w-56 place-self-center place-item-center">
                            {/* <img src={props.website.logo_url} alt="icon" className=" object-cover rounded-full h-44 w-44"></img> */}
                            {/* <img src={props.website.logo_url} alt="icon" className="h-56 w-56 object-scale-down"></img> */}
                            <GatsbyImage image={getImage(props.website.imgLogo)} alt={"Logo"} className="h-56 w-56" objectFit='scale-down' />
                        </div>
                        <div className="">
                            <h1 className="font-bold text-4xl ">{props.website.name}</h1>
                        </div>
                        <div className="text-xl">{categoryLst}</div>

                        <div className="flex  flex-row space-x-5 -mt-14">
                            {
                                props.website.social_links.facebook.trim() == ''
                                    ? null
                                    : <a href={props.website.social_links.facebook} target="_blank">
                                        <div>
                                            <img src={facebook_icon_new} alt="fb-icon" className="w-7 h-7" />
                                        </div>
                                    </a>
                            }

                            {
                                props.website.social_links.instagram.trim() == ''
                                    ? null
                                    : <a href={props.website.social_links.instagram} target="_blank">
                                        <img src={instagram_icon_1_new} className="relative" />
                                        <img src={instagram_icon_2_new} className="transform translate-x-1/2 -translate-y-5" />
                                    </a>
                            }


                        </div>
                    </div>
                </div>
            </div>

            <div className="relative w-[53%]">
                <GatsbyImage image={getImage(props.website.imgCover)} alt={"Cover image"} className="w-full h-full" objectFit="cover"/>
                <div className="absolute bottom-1/8 left-1/2 flex justify-center space-x-3 transform -translate-x-1/2">
                    <Button btnName={t('Order now')} onClick={event => window.open('https://web.nanea.app/' + props.website.webslug, '_blank')} accentColor={props.website.color} />
                    <Button btnName={t('Go to menu')} onClick={event => window.open('https://web.nanea.app/' + props.website.webslug, '_blank')} accentColor={props.website.color} />
                </div>
            </div>
        </div>
    );
};
export default PartnerGeneralInfoDesktop;
