import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "./Button";
import facebook_icon from "../images/facebook.svg";
import instagram_icon_1 from "../images/Pfad 418.svg";
import instagram_icon_2 from "../images/Pfad 419.svg";
import { Buffer } from "buffer";
import { useTranslation } from "gatsby-plugin-react-i18next";

function replaceColorSvg(svgData: string, oldCode: string, newCode: string): string {
    const prefix = "data:image/svg+xml;base64,";
    const onlyBase64 = svgData.replace(prefix, "");
    const plainTextOld = Buffer.from(onlyBase64, "base64").toString("ascii");
    const plainTextNew = plainTextOld.replaceAll(oldCode, newCode);
    const result = prefix + Buffer.from(plainTextNew, "utf8").toString("base64");
    return result;
}

const PartnerGeneralInfoMobile = (props) => {
    const categoryLst = props.website.categories.join(" | ");
    const { t } = useTranslation();

    const accentColor = '#' + props.website.color;
    const instagram_icon_1_new = replaceColorSvg(instagram_icon_1, "#36caf4", accentColor);
    const instagram_icon_2_new = replaceColorSvg(instagram_icon_2, "#36caf4", accentColor);
    const facebook_icon_new = replaceColorSvg(facebook_icon, "#36caf4", accentColor);

    return (
        <div>
            {/* <div className="flex flex-col bg-[url('/bg-pic2.png')]"  > */}
            <div className="flex flex-col relative">
                <div className="absolute">
                    <StaticImage src="./../images/bg-pic2.png" alt="Cover image" className="opacity-10 object-cover" />
                </div>
                

                <div className="">
                    {/* <img src={props.website.image_url} alt="IMG_URL"></img> */}
                    <GatsbyImage image={getImage(props.website.imgCover)} alt={"Cover image"} className="w-full h-full" objectFit="cover" />
                    <div className="relative z-20 left-1/2">
                        <div className="flex h-56 w-56 transform -translate-x-1/2 -translate-y-1/2 place-item-center">
                            {/* <img src={props.website.logo_url} alt="icon" className="h-56 w-56 object-scale-down place-self-center"></img> */}
                            <GatsbyImage image={getImage(props.website.imgLogo)} alt={"Logo"} className="h-56 w-56 place-self-center" objectFit="scale-down" />
                        </div>
                    </div>
                </div>

                <div style={{color: `#${props.website.color_text_body}`}} className="flex flex-col justify-center  -mt-10">
                    <h1 className=" font-bold text-3xl text-center">{props.website.name}</h1>
                    <div className="text-base font-medium flex text-center justify-center mt-2">{categoryLst}</div>

                    <div className="space-x-5  flex justify-center mt-10 mb-10">
                        <div className="justify-center">
                            <a href={props.website.social_links.facebook} target="_blank">
                                <div>
                                    <img src={facebook_icon_new} alt="fb-icon" className="w-7 h-7" />
                                </div>
                            </a>
                        </div>
                        <div>
                            <a href={props.website.social_links.instagram} target="_blank">
                                <img src={instagram_icon_1_new} className="relative" />
                                <img src={instagram_icon_2_new} className="transform translate-x-1/2 -translate-y-5" />
                            </a>
                        </div>
                    </div>
                    <div className=" relative right-0 z-10 grid mobile:grid-rows-2 tablet:grid-cols-2 justify-center mobile:space-y-2 tablet:space-x-3">
                        <div className="justify-self-end">
                            <Button target="_blank" btnName={t('Order now')} onClick={(event) => window.open("https://nanea.app/" + props.website.webslug, "_blank")} accentColor={props.website.color} />
                        </div>
                        <div className="justify-self-start">

                            <Button target="_blank" btnName={t('Go to menu')} onClick={(event) => window.open("https://nanea.app/" + props.website.webslug, "_blank")} accentColor={props.website.color} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnerGeneralInfoMobile;
