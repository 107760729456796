import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import ReactMarkdown from "react-markdown";
import HeaderText from "./HeaderText";

function AboutUsDesktop(props) {
    const { t } = useTranslation();
    const context = React.useContext(I18nextContext);
    return (
        <div className="flex place-content-center">
            <div className="grid grid-cols-2 pt-20 space-x-10 w-11/12">
                <div className="flex flex-col space-y-10">
                    <div className="w-3/4 place-self-end">
                        <GatsbyImage image={getImage(props.website.img1)} alt={"Image 1"} />
                    </div>
                    <div className="w-full">
                        <GatsbyImage image={getImage(props.website.img2)} alt={"Image 2"} />
                    </div>
                </div>

                <div className="flex flex-col w-10/12">
                    <div className="flex flex-col space-y-5 pt-20 w-8/12 place-self-center">
                        <HeaderText text={
                            props.website.about_us[context.language].trim() == ''
                                ? ''
                                : t('About us')} colorHeader={props.website.color_text_header} />
                        <div style={{color: `#${props.website.color_text_body}`}} className='font-medium'>
                            <ReactMarkdown>{props.website.about_us[context.language]}</ReactMarkdown>
                        </div>
                    </div>
                    <div className="pt-32">
                        <GatsbyImage image={getImage(props.website.img3)} alt={"Image 3"} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsDesktop;
