import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import HeaderText from "./HeaderText";
import OpeningHoursTable from "./OpeningHoursTable";


const OpeningHours = (props) => {

    const { t } = useTranslation();
    const context = React.useContext(I18nextContext);
 
    return (
        <div className=" pl-10 pt-20 pr-10  mobile:space-y-10 tablet:space-y-10 desktop:space-y-10  desktop:w-3/4 w-full  place-self-center">
            <div className="mobile:justify-center">
                <HeaderText text={t('Opening hours')} colorHeader={props.data.website.color_text_header}/>
            </div>
            <div className="text-textColor flex mobile:flex-col mobile:space-y-10 desktop:flex-row tablet:flex-col tablet:space-y-10 ">              
              { props.data.website.lst_opening_hours_on_site_str.length > 0 && <OpeningHoursTable order_option="on site" tableName={t('On Site')} openingHoursList={props.data.website.lst_opening_hours_on_site_str} lang={context.language} accentColor={props.data.website.color} textColor={props.data.website.color_text_body} /> }
              { props.data.website.lst_opening_hours_take_away_str.length > 0 && <OpeningHoursTable order_option="take away" tableName={t('Take Away')} openingHoursList={props.data.website.lst_opening_hours_take_away_str} lang={context.language} accentColor={props.data.website.color} textColor={props.data.website.color_text_body} /> }
              { props.data.website.lst_opening_hours_delivery_str.length > 0 &&  <OpeningHoursTable order_option="delivery" tableName={t('Delivery')} openingHoursList={props.data.website.lst_opening_hours_delivery_str} lang={context.language} accentColor={props.data.website.color} textColor={props.data.website.color_text_body} /> }
            </div>
        </div>
    );
};

export default OpeningHours;
