import React from "react";
import HeaderText from "./HeaderText";
import ReactMarkdown from "react-markdown";
import { I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next';

const RestaurantIntroduce = (props) => {
    const context = React.useContext(I18nextContext);
    const fullText = props.website.top_text[context.language];
    
    const { t } = useTranslation();    
    return (
        <div className="pl-10 pr-10 pt-20 mobile:space-y-10 mobile:justify-center desktop:pt-28 desktop:w-3/4 desktop:place-self-center ">
            <HeaderText text={t('The restaurant')} colorHeader={props.website.color_text_header}/>
            <div className="flex justify-center desktop:pt-10 tablet:pt-10 ">
                <div style={{color: `#${props.website.color_text_body}`}} className="font-medium grid desktop:grid-cols-2 mobile:grid-cols-1 tablet:grid-cols-1 gap-12 text-base">
                    <ReactMarkdown>{fullText}</ReactMarkdown>
                </div>{" "}
            </div>
        </div>
    );   
};

export default RestaurantIntroduce;
