import { graphql } from "gatsby";
import React, { CSSProperties } from "react";
import { useState } from "react";


function useHover(styleOnHover: CSSProperties, styleOnNotHover: CSSProperties = {})
{
    const [style, setStyle] = React.useState(styleOnNotHover);

    const onMouseEnter = () => setStyle(styleOnHover)
    const onMouseLeave = () => setStyle(styleOnNotHover)

    return {style, onMouseEnter, onMouseLeave}
}

const Button = (props) => {
    const [isHover, setHover] = useState(false);
    const handleMouseEnter = e => {
        setHover(true);
    };
    const handleMouseLeave = e => {
        setHover(false);
    };
    const hover = useHover(
        {
            backgroundColor: '#f9fafb', // bg-gray-50
            color: `#${props.accentColor}`
        }, 
        {
            backgroundColor: `#${props.accentColor}`,            
            color: '#f3f4f6' // text-gray-100
        });

    return (
        <button 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave}
            onClick={props.onClick}      
            style={{
                backgroundColor: `#${props.accentColor}`,                
            }}
            {...hover}
            className={`desktop:w-40 mobile:w-52 h-10 tablet:w-44 rounded-full focus:border-4 border-blue-300`}>
            <div className="flex justify-around transform duration-500 ">
                <div className="font-medium">{props.btnName}</div>
                {/* {isHover && <div className="font-medium pt-1">{'→'}</div> } */}
            </div>
        </button>
    );
};

export default Button;

// export const query =  graphql`
//   query {
//     website {
//         color
//     }
//   }`
